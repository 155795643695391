import { cleanEmptyObjectKeys } from 'src/Elements/Utils/general'

export const getSSRGlobalResourceData = (): any => {
  const productSelectType = globalThis.globalResourceData.product_quantity_type ?? 'single'
  const { checkout_v2_state, contact } = globalThis?.sessionData ?? {}
  const initialContactData =
    checkout_v2_state === 'guest'
      ? {}
      : cleanEmptyObjectKeys({
          first_name: contact?.first_name ?? '',
          last_name: contact?.last_name ?? '',
          email: contact?.email ?? '',
          phone_number: contact?.phone_number ?? '',
        })

  const { products, productsById, variantsById, pricesById } = globalThis.globalResourceData
  const savedPaymentMethods = contact?.payment_methods ?? []

  return {
    productSelectType,
    products,
    productsById,
    variantsById,
    pricesById,
    savedPaymentMethods,
    initialContactData,
  }
}
